.PageError {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  margin: 10px 0;
  padding: 10px;
  border-radius: 3px 3px 3px 3px;
  color: #9f6000;
  background-color: #feefb3;
}
