.HeroGrid {
  display: grid;
  grid-template-columns: auto auto auto;
}

.HeroGrid_Title {
  grid-row-start: 1;
  grid-row-end: 2;
}

.HeroGrid_HeroImage {
  grid-row-start: 2;
  grid-row-end: 4;

  display: -webkit-box;

  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-pack: center;
          justify-content: center;
}

.HeroGrid_Register {
  grid-row-start: 4;
  grid-row-end: 5;
}

@media (min-width: 768px) {
  .HeroGrid_Title {
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .HeroGrid_HeroImage {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 4;

    -webkit-box-orient: vertical;

    -webkit-box-direction: normal;

            flex-direction: column;
  }

  .HeroGrid_Register {
    grid-row-start: 2;
    grid-row-end: 3;
  }
  }
