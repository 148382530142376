.TopNav {
  margin-top: 8px;
  text-align: left;
  margin-bottom: 0px;
  display: -webkit-box;
  display: flex;
  justify-content: space-around;
}

.TopNav__LogoContainer {
  margin-top: 12px;
}

.TopNav__SocialMediaContainer {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  margin-left: 30px;
}

@media (min-width: 360px) {
  .TopNav {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .TopNav__SocialMediaContainer {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
            flex-direction: row-reverse !important;
    margin-top: 0px !important;
  }
  }

.TopNav__LoginContainer {
  height: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  /*margin-top: 0px !important;*/
  flex-wrap: nowrap;
}

@media (min-width: 768px) {
  .TopNav {
    padding-right: 22px;
  }
  }

.TopNav__LoginSocialMedia {
  /*align-items: flex-end;*/
  /*margin-top: 16px;*/
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
}
