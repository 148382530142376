.Controls {
  position: relative;

  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);

  /*background-color: var(--cp1_pink);*/
}

.Controls__Toolbar {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: justify;
          justify-content: space-between;
}

.Controls__Container {
  margin-top: 6px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-align: center;
          align-items: center;
  justify-content: space-around;
}

.Controls__Back {
  margin-right: 10vw;
  height: 24px;
}

.Controls__Forward {
  height: 24px;
  margin-left: 10vw;
  /*transform: scaleX(-1);*/
}

.Controls-play {
  right: 0px;
  bottom: 0px;
  width: 64px;
  height: 64px;
  border-radius: 100px;
  border: 1px solid black;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
}

.Controls-playIcon {
  font-size: 75px !important;
  color: rgb(117, 117, 117);
}

.Controls__Parts {
  height: 40px !important;
}

.Controls__Parts select {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

#controls-floating-action-button {
  position: absolute;
  right: 0px;
  top: -68px;

  margin-bottom: 15px;
  margin-right: 15px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
          justify-content: flex-end;
  z-index: 5;
}
