.LoginButtons {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
          justify-content: flex-end;
}

.LoginButtons__Container {
  max-width: 320px;
}
