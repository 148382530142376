.SocialMediaLinks {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  width: 104px;
  -webkit-box-align: center;
          align-items: center;
  min-width: 100px;
}
