.FeedbackPage {
  font-family: 'Platform Web';
  height: 100%;
  margin-top: 18vh;
}

.FeedbackPage__Title {
  font-family: 'Platform Web';
  font-weight: 300;
  font-size: xx-large;
}

.FeedbackPage__Button {
  margin-top: 10vh;
}

.FeedbackPage__Email {
  margin-top: 10vh;
  font-size: 14px;
}

.FeedbackPage__Version {
  margin-top: 10vh;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
  /*font-size: 14px;*/
}
