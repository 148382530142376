:root {
  --choirpal-grey-dark: #262626;
  --choirpal-grey-mid: #7f7f7f;
  --choirpal-grey-light: #e4e6e8;

  --choirpal-white: white;

  --choirpal-red-100: #ff6b6f;
  --choirpal-red-80: #ff898b;
  --choirpal-red-60: #ffa8a9;
  --choirpal-red-40: #ffc5c6;
  --choirpal-red-20: #ffe1e1;

  --choirpal-pink-100: #ffe5d9;
  --choirpal-pink-80: #ffeae1;
  --choirpal-pink-60: #fff0e8;
  --choirpal-pink-40: #fff5ef;
  --choirpal-pink-20: #fffaf8;

  --choirpal-blue-100: #1d428a;
  --choirpal-blue-80: #4b69a1;
  --choirpal-blue-60: #798eba;
  --choirpal-blue-40: #a5b4d1;
  --choirpal-blue-20: #d3d9e8;

  --choirpal-yellow-100: #ffc56e;
  --choirpal-yellow-80: #ffd08c;
  --choirpal-yellow-60: #ffdcaa;
  --choirpal-yellow-40: #ffe8c6;
  --choirpal-yellow-20: #fff4e3;

  --cp_grey_dark: #262626;
  --cp_grey_mid: #7f7f7f;
  --cp_grey_light: #e4e6e8;

  --cp1_red: #ff6b6f;
  --cp1_pink: #ffe5d9;
  --cp1_blue: #1d428a;
  --cp1_yellow: #ffc56e;

  --cp2_red: #ffa8a9;
  --cp2_red_soft: #ffc5c6;
  --cp2_pink: #fff2ec;
  --cp2_blue: #798eba;
  --cp2_green: #708690;

  /* Coolors Exported Palette - coolors.co/ffc9b5-f7b1ab-d8aa96-807182-c7d3bf */
  --clr-primary: #5f6368;
  --clr-secondary: #d8aa96;
  --clr-tertiary: #fafafa;
  --clr-black: #807182;
  --clr-error: #f7b1ab;
}

.ProgressBar {
  height: 3px;
  position: relative;
  background-color: color(gray tint(95%));
  border-top: 1px solid white;
}

.ProgressBar__TimestampContainer {
  position: absolute;
  left: 0px;
  bottom: 4px;
  padding: 1px 4px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  font-size: 12.4px;
  width: 98%;
  z-index: 1;
}

.ProgressBar__PositionIndicator {
  z-index: 2;
  height: inherit;
  background-color: var(--choirpal-red-80);
  position: absolute;
}

.ProgressBar__BufferContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: flex;
}

.ProgressBar__BufferChunk {
  position: relative;
  background-color: var(--choirpal-red-20);
  width: 50px;
  height: 100%;
  left: 20px;
}
