.LoginFrame {
  height: 100vh;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
  text-align: center;
  width: 100%;
}

.LoginFrame__Content {
  max-width: 600px;
  /*background-color: lightblue;*/
  width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: justify;
          justify-content: space-between;
}
